<template>
	<div class="journalDetails page-content">
		<div class="journalDetails1">
			<div style="margin-left: 30px; margin-top: 20px; width: 168px; height: 222px">
				<!-- <el-image style="width: 168px; height: 222px" :src="journalDetails.spareFieldO"></el-image> -->

				<el-image style="width: 168px; height: 222px" :src="journalDetails.spareFieldO"
					v-if="journalDetails.spareFieldO!='noPhoto.jpg'">
				</el-image>
				<div v-else>
					<el-image style="width: 150px; height: 210px"
						:src="require('@/assets/images/noPhoto.jpg')"></el-image>
					<div class="noPhoto">
						<div style="height: 42px;max-width: 150px;padding: 10px;" class="three">
							{{journalDetails.publisherName}}
						</div>
						<div style="height: 150px;max-width: 150px;padding: 10px;color: #333333;font-style: oblique;font-weight: 600;"
							class="eight">
							{{journalDetails.journalTitle}}
						</div>
					</div>
				</div>

			</div>
			<div style="margin-left: 40px">
				<div class="journalDetails1Title two">
					<p>{{isnullFilter(journalDetails.journalTitle)}}</p>
				</div>
				<div class="journalDetails1Div">
					<p><span>ISSN:</span><span>{{isnullFilter(journalDetails.journalIssn)}}</span>
					</p>
					<p><span>e-ISSN:</span><span>{{isnullFilter(journalDetails.journalEssin)}}</span>
					</p>
					<p><span>出版商:</span>
						<span>
							{{isnullFilter(journalDetails.publisherName)}}
						</span>

					</p>
				</div>

			</div>
			<div style="margin-left: -15%;margin-top:7%">
<!--				<el-button round @click="btnCli">在线投稿</el-button>-->
				<el-row class="mb-4">
					<router-link target="_blank" :to="{path:'contribution',query:{journalTitle : isnullFilter(journalDetails.journalTitle)}}">
						<el-button size="large"><span style="font-size: 18px">在线投稿</span></el-button>
					</router-link>
				</el-row>
			</div>
			<!-- 原有的注释 -->
			<!-- <div>
				<div style="float: left; padding-top: 140px">
					<p v-if="journalDetails.isOa!='NO'&&journalDetails.isOa!='-'">
						<i class="el-icon-lock" style="color: #ff822e"></i><span
							style="color:color: #333333;font-size: 14px;">Open Access</span>
					</p>
				</div>
				<div style="float: right; padding-top: 80px" v-if="journalDetails.activeName=='EI'">
					<el-image style="width: 168px; height: 222px" :src="require('@/assets/images/journalEija.png')">
					</el-image>
				</div>
			</div> -->
			<!-- 原有的注释 -->
			
		</div>
		<div class="journalDetails2" style="margin-top: 75px;" v-if="journalDetails.activeName=='EI'">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333">期刊信息</div>
			</div>
			<div style="border: solid 2px #eeeeee;margin-top: 40px;">
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">期刊/会议类型</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">{{isnullFilter(journalDetails.journalType)}}</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">国家</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">{{isnullFilter(journalDetails.countryRegion)}}</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">收录数据库</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">{{isnullFilter(journalDetails.includedDatabase)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<!-- 原有的注释 -->
				<!-- <el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">投稿要求</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							<div class="num" style="border-radius: 5px; width: 115px">
								Author Guidline
							</div>
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row> -->
				<!-- 原有的注释 -->
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">学科1</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.subjectOne)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">学科2</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.subjectTwo)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">学科3</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.subjectThree)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">学科4</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.subjectFour)}}
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="journalDetails2" style="margin-top: 75px;"
			v-else-if="journalDetails.activeName=='SCI'||journalDetails.activeName=='SSCI'">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333">期刊信息</div>
			</div>
			<div style="border: solid 2px #eeeeee;margin-top: 40px;">
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">中文名称</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.journalTitleCn)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">发文频率</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.frequency)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">2021期刊影响</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">{{numFilter2(journalDetails.yearIf)}}</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">发文量</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{numFilter0(journalDetails.documents)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">中国人比例</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{numFilter2(journalDetails.scaleCn)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">收录数据库</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.includedDatabase)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">国家</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.category)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">期刊缩写</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.abbreviation)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">自引率</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{numFilter2(journalDetails.citationRate)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">中科院分区</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.zkySubregion)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">是否TOP</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.isTop)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">h指数</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.hIndex)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">citeScore</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.citescore)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">是否中科院预警</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.isWarning)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">2021 JCI</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.yearJci)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">JCR分区</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two" :title="journalDetails.jcrSubregion">
							{{isnullFilter(journalDetails.jcrSubregion)}}
						</div>
					</el-col>
				</el-row>
			</div>

		</div>
		<div class="journalDetails2" style="margin-top: 75px;" v-else-if="journalDetails.activeName=='AHCI'">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333">期刊信息</div>
			</div>
			<div style="border: solid 2px #eeeeee;margin-top: 40px;">
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">期刊名</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.journalTitle)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">出版商地址</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">{{isnullFilter(journalDetails.publisherAddress)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">语言</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">{{isnullFilter(journalDetails.journalLanguages)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">期刊缩写</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">{{isnullFilter(journalDetails.abbreviation)}}</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">类别</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two" :title="journalDetails.category">
							{{isnullFilter(journalDetails.category)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">2021 JCI</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.yearJci)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">JCI排名</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.jciRank)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">总引用</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.totalCitations)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">可引用条目中文章百分比</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.articlesInCitableItems)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">收录数据库</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.journalIndex)}}
						</div>
					</el-col>
				</el-row>
			</div>
		</div>

		<div class="journalDetails2" style="margin-top: 75px;" v-else-if="journalDetails.activeName=='ESCI'">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333">期刊信息</div>
			</div>
			<div style="border: solid 2px #eeeeee;margin-top: 40px;">
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">期刊名</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.journalTitle)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">出版商地址</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.PublisherAddress)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">语言</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.journalLanguages)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">Web of Science Categories</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.WebOfScienceCategories)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">收录数据库</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.includedDatabase)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">发文量</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.documents)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">2021 JCI</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light two">
							{{isnullFilter(journalDetails.yearJci)}}
						</div>
						<div>
							<el-divider style="height: 2px; color: #eeeeee"></el-divider>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<div class="grid-content bg-purple">JCI排名</div>
					</el-col>
					<el-col :span="18">
						<div class="grid-content bg-purple-light">
							{{isnullFilter(journalDetails.jciRank)}}
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 原有的注释 -->
		<!-- <div class="journalDetails2" style="margin-top: 75px" v-if="journalDetails.classification=='EI'">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333">5年发文量趋势图</div>
			</div>
			<div>
				<div class="chart">
					<div id="chart" :style="{ width: '1300', height: '500px' }"></div>
				</div>
			</div>
		</div> -->
		<!-- 原有的注释 -->
		<div class="journalDetails2 commentListLoading" style="margin-top: 75px">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333; margin-top: -25px">
					<p>
						<span style="margin-right: 10px">作者评价</span><span
							style="font-size: 14px">({{dataCount}}条)</span>
					</p>
				</div>
			</div>
			<div style="width: 90%; margin: auto" v-for="(comment, commentIndex) in commentList" :key="commentIndex">
				<div style="display: flex">
					<div class="block" style="margin-right: 20px">
						<el-avatar :size="50" :src="comment.userAvatar"></el-avatar>
					</div>
					<div style="padding-top: 15px; width: 90%">
						<div style="float: left; color: #1b5faa">
							{{ comment.userName }}
						</div>
						<div style="float: right">{{ comment.createDate }}</div>
					</div>
				</div>
				<div class="comment">
					<div>
						<p>
							{{ comment.comment }}
						</p>
					</div>
					<div>
						<p style="margin-top: 22px">
							<span style="margin-right: 30px">
								<el-link icon="el-icon-thumb" :underline="false" style="font-size: 18px"
									href="javascript:void(0);">赞
									{{ comment.giveNum }}
								</el-link>
							</span>
							<span>
								<el-link :underline="false" href="javascript:void(0);" icon="el-icon-chat-dot-square"
									@click="inputAClick(comment.id)" style="font-size: 18px">回复</el-link>
							</span>
						</p>
					</div>
					<div v-if="comment.id==inputA">
						<el-input placeholder="请输入内容" v-model="commentReply.comment" :clearable="true">
							<template #append>
								<el-button type="primary" style="background-color: #767288; color: #ffffff"
									@click="addAReply(commentIndex)">回复
								</el-button>
							</template>
						</el-input>
					</div>
					<div style="margin-top: 20px;">
						<div style="margin-left: 30px" v-for="(reply, replyIndex) in comment.replyList"
							:key="replyIndex">
							<div style="display: flex">
								<div class="block" style="margin-right: 20px">
									<el-avatar :size="50" :src="reply.fromUserAvatar"></el-avatar>
								</div>
								<div style="padding-top: 15px; width: 90%">
									<div style="float: left">
										<span style="color: #1b5faa; margin-right: 8px">{{
                          reply.fromUserName
                        }}</span>回复<span style="color: #1b5faa; margin-left: 8px">{{
                          reply.toUserName
                        }}</span>
									</div>
									<div style="float: right">{{ reply.createDate }}
									</div>
								</div>
							</div>
							<div style="margin-left: 70px">
								<div>
									<p>
										{{ reply.comment }}
									</p>
								</div>
								<div>
									<p style="margin-top: 22px">
										<span style="margin-right: 30px">
											<el-link icon="el-icon-thumb" :underline="false" href="javascript:void(0);"
												style="font-size: 18px">赞 {{ reply.fiveNum }}</el-link>
										</span>
										<span>
											<el-link icon="el-icon-chat-dot-square" :underline="false"
												href="javascript:void(0);" @click="inputBClick(reply.id)"
												style="font-size: 18px">回复</el-link>
										</span>
									</p>
								</div>
								<div v-if="reply.id==inputB">
									<el-input placeholder="请输入内容" v-model="commentReply.comment" :clearable="true">
										<template #append>
											<el-button type="primary" style="background-color: #767288; color: #ffffff"
												@click="addBReply(commentIndex,replyIndex)">
												回复</el-button>
										</template>
									</el-input>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div>
				<div class="pagination">
					<el-pagination background layout="prev, pager, next" :total="dataCount"
						@current-change="handleCurrentChange">
					</el-pagination>
				</div>
			</div>
		</div>
		<div class="journalDetails2" style="margin-top: 75px">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333; margin-top: -25px">
					<p>
						<span style="margin-right: 10px">发布评论</span><span
							style="font-size: 14px">(内容经网站管理员审核通过后发布)</span>
					</p>
				</div>
			</div>
			<div style="margin-left: 10%; width: 90%" class="commentLoading">
				<el-form-item>
					<el-input type="textarea" v-model="comment.comment"></el-input>
				</el-form-item>
				<el-form-item style="float: right">
					<el-button type="primary" @click="addcomment()" style="background-color: #767288; width: 130px">提交
					</el-button>
				</el-form-item>
			</div>
		</div>
		<div class="journalDetails2" style="margin-top: 75px">
			<div style="margin: 0 auto; width: 12.5%">
				<p style="font-size: 30px; color: #333333">推荐期刊</p>
				<el-divider style="margin-top: -10%; height: 2px"></el-divider>
				<el-divider style="
            margin-top: -1%;
            background-color: red;
            height: 2px;
            width: 51%;
          "></el-divider>
			</div>
			<div style="display: flex; margin-top: 50px;justify-content: space-between;">
				<div style="width: 200px;" v-for="(journal, journalIndex) in journalList.slice(0,5)" :key="journalIndex"
					@click="updateJournalDetails(journalIndex)" class="cursor">
					<div>
						<!-- <el-image :src="journal.spareFieldO" style="width: 200px; height: 250px"></el-image> -->

						<el-image style="width: 168px; height: 222px" :src="journal.spareFieldO"
							v-if="journal.spareFieldO!='noPhoto.jpg'">
						</el-image>
						<div v-else>
							<el-image style="width: 150px; height: 210px"
								:src="require('@/assets/images/noPhoto.jpg')"></el-image>
							<div class="noPhoto">
								<div style="height: 42px;max-width: 150px;padding: 10px;" class="three">
									{{journal.publisherName}}
								</div>
								<div style="height: 150px;max-width: 150px;padding: 10px;color: #333333;font-style: oblique;font-weight: 600;"
									class="eight">
									{{journal.journalTitle}}
								</div>
							</div>
						</div>

					</div>
					<div class="two journalTiele">
						{{ journal.journalTitle }}
					</div>
					<div style=" margin-top: 40px">
						<p class="one">
							<span style="color: #767288">ISSN:</span><span
								style="color: rgb(172 172 172)">{{ journal.journalIssn }}</span>
						</p>
						<p class="one">
							<span style="color: #767288">e-ISSN:</span><span
								style="color: rgb(172 172 172)">{{ journal.journalEssin }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	// import * as echarts from "echarts";
	import $http from "@/request/http";
	import journalismDetails from "./journalismDetails";
	export default {
		data() {
			return {
				inputA: 0,
				inputB: 0,
				dataCount: 0,
				currentPage: 1,
				userInfo: {},
				comment: {
					commentType: "journal",
					commentId: "",
					comment: "",
					giveNum: "0",
					userName: "",
					userId: "",
					userAvatar: ""
				},
				commentReply: {
					commentId: "",
					comment: "",
					fiveNum: "0",
					fromUserName: "",
					fromUserId: "",
					fromUserAvatar: "",
					toUserName: "",
					toUserId: "",
					toUserAvatar: ""
				},
				journalDetails: {},
				journalList: [],
				commentList: [],
			};
		},

		methods: {
			isnullFilter(value) {
				if (value == 'null' || value == "" || value == null || value == undefined) {
					return "暂无数据"
				} else {
					return value
				}
			},
			numFilter2(value) {
				const realVal = parseFloat(value).toFixed(2)
				return realVal
			},
			numFilter0(value) {
				const realVal = parseFloat(value).toFixed(0)
				return realVal
			},

			inputAClick(id) {
				this.commentReply.comment = ''
				this.inputA = id
			},
			inputBClick(id) {
				this.commentReply.comment = ''
				this.inputB = id
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.queryCommentList();
			},
			addcomment() {
				let _this = this
				if (this.$store.getters.getIsLogin != "true") {
					this.$notify.error({
						title: "错误",
						message: "请先登录！",
					});
					return false;
				}
				if (this.comment.comment == "" || this.comment.comment == null) {
					this.$notify.error({
						title: "错误",
						message: "请输入评论内容！",
					});
					return false;
				}
				this.comment.commentId = this.journalDetails.id
				this.comment.userName = this.userInfo.name
				this.comment.userId = this.userInfo.id
				this.comment.userAvatar = this.userInfo.avatar
				$http.post("addComment", this.comment, "commentLoading")
					.then(function(res) {
						if (res.code == 200) {
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg,
								type: "success",
								onClose: function() {
									_this.inputA = 0
									_this.comment.comment = ""
									_this.commentList.splice(0, 0, res.data)
								},
							});
						}
					})
					.catch(function() {});
			},
			addAReply(commentIndex) {
				let _this = this
				if (this.$store.getters.getIsLogin != "true") {
					this.$notify.error({
						title: "错误",
						message: "请先登录！",
					});
					return false;
				}
				if (this.commentReply.comment == "" || this.commentReply.comment == null) {
					this.$notify.error({
						title: "错误",
						message: "请输入评论内容！",
					});
					return false;
				}
				this.commentReply.commentId = this.commentList[commentIndex].id
				this.commentReply.fromUserName = this.userInfo.name
				this.commentReply.fromUserId = this.userInfo.id
				this.commentReply.fromUserAvatar = this.userInfo.avatar
				this.commentReply.toUserName = this.commentList[commentIndex].userName
				this.commentReply.toUserId = this.commentList[commentIndex].userId
				this.commentReply.toUserAvatar = this.commentList[commentIndex].userAvatar
				$http.post("addCommentReply", this.commentReply, "commentLoading")
					.then(function(res) {
						if (res.code == 200) {
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg,
								type: "success",
								onClose: function() {
									_this.inputA = 0
									_this.commentReply.comment = ""
									if (_this.commentList[commentIndex].replyList == null) {
										_this.commentList[commentIndex].replyList = new Array()
										_this.commentList[commentIndex].replyList.push(res.data)
									} else {
										_this.commentList[commentIndex].replyList.splice(0, 0, res.data)
									}
								},
							});
						}
					})
					.catch(function() {});
			},
			addBReply(commentIndex, replyIndex) {
				let _this = this
				if (this.$store.getters.getIsLogin != "true") {
					this.$notify.error({
						title: "错误",
						message: "请先登录！",
					});
					return false;
				}
				if (this.commentReply.comment == "" || this.commentReply.comment == null) {
					this.$notify.error({
						title: "错误",
						message: "请输入评论内容！",
					});
					return false;
				}
				this.commentReply.commentId = this.commentList[commentIndex].id
				this.commentReply.fromUserName = this.userInfo.name
				this.commentReply.fromUserId = this.userInfo.id
				this.commentReply.fromUserAvatar = this.userInfo.avatar
				this.commentReply.toUserName = this.commentList[commentIndex].replyList[replyIndex].fromUserName
				this.commentReply.toUserId = this.commentList[commentIndex].replyList[replyIndex].fromUserId
				this.commentReply.toUserAvatar = this.commentList[commentIndex].replyList[replyIndex].fromUserAvatar
				$http.post("addCommentReply", this.commentReply, "commentLoading")
					.then(function(res) {
						if (res.code == 200) {
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg,
								type: "success",
								onClose: function() {
									_this.inputB = 0
									_this.commentReply.comment = ""
									_this.commentList[commentIndex].replyList.push(res.data)
								},
							});
						}
					})
					.catch(function() {});
			},


			// echartsInit() {
			// 	let column = echarts.init(document.getElementById("chart"));
			// 	column.setOption({
			// 		color: ["#375691"],
			// 		// title: {
			// 		//   text: "",
			// 		//   left: "left",
			// 		//   textStyle: {
			// 		//     //文字颜色
			// 		//     color: "#375691",
			// 		//     fontSize: 24,
			// 		//   },
			// 		// },
			// 		legend: {
			// 			data: ["5年发文量趋势图"],
			// 		},
			// 		tooltip: {
			// 			showContent: true,
			// 			trigger: "axis",
			// 			axisPointer: {
			// 				// 坐标轴指示器，坐标轴触发有效
			// 				type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
			// 			},
			// 			// formatter: function (params) {
			// 			//   console.log(params);
			// 			//   // return "发文量：" + params;
			// 			// },
			// 		},
			// 		label: {
			// 			show: true,
			// 			position: "top",
			// 			color: ["#1b5faa"],
			// 		},
			// 		xAxis: [{
			// 			type: "category",
			// 			name: "年份",
			// 			data: ["2016年", "2017年", "2018年", "2019年", "2020年"],
			// 			axisTick: {
			// 				alignWithLabel: false, //刻度线和标签对齐
			// 			},
			// 			nameTextStyle: {
			// 				color: "#333333;",
			// 				fontSize: 18,
			// 			},
			// 			// axisLabel: {
			// 			//   show: true,
			// 			//   textStyle: {
			// 			//     color: "#c3dbff", //更改坐标轴文字颜色
			// 			//     fontSize: 14, //更改坐标轴文字大小
			// 			//   },
			// 			// },
			// 		}, ],
			// 		yAxis: [{
			// 			type: "value",
			// 			name: "总发文量",
			// 			nameTextStyle: {
			// 				color: "#333333;",
			// 				fontSize: 18,
			// 			},
			// 		}, ],
			// 		series: [
			// 			// {
			// 			//   // For shadow
			// 			//   type: "bar",
			// 			//   itemStyle: {
			// 			//     color: "rgba(0,0,0,0.05)",
			// 			//   },
			// 			//   barGap: "-100%",
			// 			//   barCategoryGap: "40%",
			// 			//   // data: dataShadow,
			// 			//   animation: false,
			// 			// },
			// 			{
			// 				type: "bar",
			// 				itemStyle: {
			// 					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
			// 							offset: 0,
			// 							color: "#1b5faa"
			// 						},
			// 						{
			// 							offset: 0.5,
			// 							color: "#1b5faa"
			// 						},
			// 						{
			// 							offset: 1,
			// 							color: "#00b4e4"
			// 						},
			// 					]),
			// 				},
			// 				emphasis: {
			// 					itemStyle: {
			// 						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
			// 								offset: 0,
			// 								color: "#00b4e4"
			// 							},
			// 							{
			// 								offset: 0.7,
			// 								color: "#1b5faa"
			// 							},
			// 							{
			// 								offset: 1,
			// 								color: "#1b5faa"
			// 							},
			// 						]),
			// 					},
			// 				},

			// 				barWidth: "40%",
			// 				data: [
			// 					parseInt(this.journalDetails.yearContOne),
			// 					parseInt(this.journalDetails.yearContTwo),
			// 					parseInt(this.journalDetails.yearContThree),
			// 					parseInt(this.journalDetails.yearContFour),
			// 					parseInt(this.journalDetails.yearContFive)
			// 				],
			// 			},
			// 		],
			// 	});
			// },
			queryCommentList() {
				let _this = this;
				if (this.journalDetails.id == null || this.journalDetails.id == "") {
					return false
				}
				let paramMap = {
					page: this.currentPage,
					commentType: "journal",
					commentId: this.journalDetails.id
				};
				$http
					.post("queryCommentList", paramMap, "commentListLoading")
					.then(function(res) {
						_this.commentList = res.data
						_this.dataCount = res.dataCount;
						_this.courPage = res.pageNo;

					})
					.catch(function() {});
			},
			randomJournalList() {
				let _this = this
				let paramMap = _this.journalDetails
				$http
					.post("randomIamsetJournalList", paramMap)
					.then(function(res) {
						_this.journalList = res.data
					})
					.catch(function() {});
			},
			updateJournalDetails(journalIndex) {
				let activeName = this.journalDetails.activeName
				this.journalDetails = this.journalList[journalIndex]
				this.journalDetails.activeName = activeName
				var timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 50)
				this.queryCommentList()
			},
			
		},
		mounted() {
			// if (this.journalDetails.classification == 'EIJA') {
			// 	this.echartsInit();
			// }
			
		},
		created() {
			this.isLogin = this.$store.getters.getIsLogin
			this.userInfo = this.$store.getters.getUserInfo
			this.journalDetails = this.$route.params
			// this.getDetails()
			// this.getCharts()
			this.queryCommentList()
			this.randomJournalList()
		},
		// computed:{
		// 	dateFormat(date){
		// 		return Moment(date).format('YYYY-MM-DD HH:mm:ss')
		// 	}
		// }
	};
</script>

<style lang="scss" scoped>
	::v-deep .el-button{
		width: 200px;
		height: 50px;
	}

	.journalTiele {
		height: 40px;
		font-weight: 600;
		margin-top: 10px;
		text-align: center;
		font-size: 14px;
	}

	.pagination {
		text-align: center;
		margin-top: 50px;
	}

	.comment {
		padding-right: 55px;
		padding-left: 70px;
		margin: auto;
		text-align: justify;
	}

	.two {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.journalDetails1 {
		// padding-left: 280px;
		display: flex;
		height: 200px;
		background-image: linear-gradient(90deg, #dbd6e8 0%, #c6d7de 100%),
			linear-gradient(#c4ccd6, #f2f3f4);
		background-blend-mode: normal, normal;
	}

	.journalDetails1Div {
		line-height: 6px;
		color: #767288;
	}

	.journalDetails1Title {
		height: 100px;
		width: 1300px;
		font-family: Arial-BoldMT;
		color: #1b5faa;
		font-size: 28px;
		font-weight: 600;
	}

	.el-icon-s-grid {
		position: absolute;
		padding-top: 5px;
		font-size: 30px;
		color: #1b5faa;
	}

	.journalDetails2-2 {
		margin: 0 auto;
		width: 80%;
		margin-top: 50px;
	}

	.journalDetails2-1 {
		background-color: #eeeeee;
		border: dashed 2px #aaaaaa;
		height: 100px;
		margin: 0 auto;
		width: 80%;
		color: #333333;
	}

	.journalDetails2-3 {
		background-color: #eeeeee;
		height: 45px;
		margin: 0 auto;
		width: 90%;
		color: #333333;
	}

	.num {
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
			linear-gradient(#d40016, #d40016);
		background-blend-mode: normal, normal;
	}

	.bg-purple {
		background: #eeeeee;
		text-align: center;
	}

	.bg-purple-light {
		padding-left: 40px;
		background: white;
	}

	.grid-content {
		padding-top: 20px;
		height: 40px;
	}

	.el-divider--horizontal {
		display: block;
		height: 1px;
		width: 100%;
		margin: 0;
	}

	.el-divider {
		background-color: #eeeeee;
	}
</style>
<style>
	.el-textarea__inner {
		height: 100px;
	}

	/*.el-tabs__nav-scroll {
		height: 65px;
	}

	 .el-tabs__active-bar {
		bottom: -16px;
	} */

	.el-upload-list__item.is-success.focusing .el-icon-close-tip {
		display: none !important;
	}
</style>