<template>
	<div class="">
		<p v-html="journalismDetails.richExt" class="p"></p>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				journalismDetails:{},
			};
		},
		created() {
			this.journalismDetails = this.$route.params
			
		},
	};
</script>

<style lang="scss" scoped>
	.p{
		.table{
			border: none;
			width: 100%;
			background-color: white
		}
	}
</style>
